<template>
  <div :key="`teams/${teamFilter}`">
    <algolia-task-list
      :user-id="userId"
      :team-filter="teamFilter"
      :route-func="
        hit => ({ path: `/admin/teams/${teamFilter}/${hit.taskId}` })
      "
    >
      <template slot="nosearch" slot-scope="{ isEmpty }">
        <list
          v-show="isEmpty"
          ref="list"
          :reference="reference"
          :filters="filters"
          :sorters="sorters"
          :active-sort="defaultSort"
        >
          <template slot-scope="task">
            <list-card
              :color="$_.get(task.item, `colors.${userId}`, '')"
              :title="`${task.item.reference}`"
              :subtitle-dark="
                task.item.fqdn
                  ? `${task.item.taskType} (${task.item.fqdn})`
                  : task.item.taskType
              "
              :subtitle-grey="getEmail(task.item)"
              :label="$store.getters['tasks/task/status'](task.item.status)"
              :timestamp="getTimestamp(task.item)"
              :avatar="getAvatar(task.item)"
              :route="{ path: `/admin/teams/${teamFilter}/${task.item._id}` }"
            >
              <strong slot="title" class="is-spaced-apart">
                {{ task.item.reference }}
                <b-icon
                  v-if="getPriorityIcon(task.item.priority)"
                  v-bind="getPriorityIcon(task.item.priority)"
                />
              </strong>
            </list-card>
          </template>
        </list>
      </template>
    </algolia-task-list>
  </div>
</template>

<script>
import { teams, getPriorityIcon } from "@src/data/task";
import { collection, orderBy, query, where } from "@firebase/firestore";

export default {
  name: "AdminTeams",
  components: {
    "algolia-task-list": () => import("@shared/tasks/_algoliaTaskList.vue")
  },
  data() {
    return {
      filters: []
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    teamFilter() {
      return this.$route.params.teamFilter;
    },
    validFilter() {
      return this.$_.map(teams, team => {
        return team.key;
      }).includes(this.teamFilter);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    reference() {
      switch (this.teamFilter) {
        case teams.MAINTENANCE.key:
          return query(
            collection(this.$firestore, "tasks"),
            where("team", "==", teams.MAINTENANCE.key),
            where("status", ">", "1-01"),
            orderBy("status", "desc")
          );
      }
    },
    sorters() {
      switch (this.teamFilter) {
        default:
          return [
            {
              field: "dateUpdated",
              label: "Last Updated"
            }
          ];
      }
    },
    defaultSort() {
      switch (this.teamFilter) {
        default:
          return { field: "dateUpdated", direction: "desc" };
      }
    }
  },
  watch: {
    teamFilter() {
      this.$nextTick(() => {
        this.$refs.algoliaSearch.$el.querySelector("input").focus();
      });
    }
  },
  created() {
    if (!this.validFilter) {
      this.$router.push({ path: `/admin/teams` });
    }
  },
  methods: {
    getPriorityIcon,
    getAvatar(task) {
      if (this.$_.isEmpty(task.alphaAgent)) return null;
      return {
        name: this.$_.get(
          task,
          `participantsData.${task.alphaAgent.id}.name`,
          ""
        ),
        url: this.$_.get(
          task,
          `participantsData.${task.alphaAgent.id}.avatarUrl`,
          ""
        )
      };
    },
    getTimestamp(task) {
      return task[
        this.$_.get(this.$refs.list, "table.sort.field", this.defaultSort.field)
      ].toDate();
    },
    getEmail(task) {
      return this.$_.has(task, `participantsData.${task.authorId}`)
        ? task.participantsData[task.authorId].email
        : "";
    }
  }
};
</script>
